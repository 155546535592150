import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { Stripe } from '@stripe/stripe-js';

import { useTenantSecret } from '../hooks';
const stripeJs = async () => await import('@stripe/stripe-js');

type StripeContextValue = Promise<Stripe | null>;

const StripeContext = createContext<StripeContextValue>(Promise.resolve(null));

export const useStripeContext = () => useContext(StripeContext);

export const StripeProvider: FC<PropsWithChildren> = ({ children }) => {
  const stripePublicKey: string | undefined = useTenantSecret('REACT_APP_STRIPE_PUBLIC_KEY');
  const contextValue = useMemo(async () => {
    if (!stripePublicKey) return Promise.resolve(null);
    return stripeJs().then(({ loadStripe }) => loadStripe(stripePublicKey));
  }, [stripePublicKey]);

  return <StripeContext.Provider value={contextValue}>{children}</StripeContext.Provider>;
};
