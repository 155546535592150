import { AxiosInstance } from 'axios';

import {
  DeliverInventoryRequestParams,
  FileUploadResponse,
  Inventory,
  InventoryQrCodeUploadRequestParams,
  InventoryReserveRandomRequestParams,
  InventoryReserveRequestParams,
  InventorySimUploadRequestParams,
  InventoryTypeDetails,
  InventoryUnreserveRequestParams,
  QueryInventoryBySerialRequestParams,
  ReservedInventory,
} from './types';

export class InventoryApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async inventoryReserve(params: InventoryReserveRequestParams) {
    return await this.axios.post<ReservedInventory>('/inventory/api/inventory/reserve', params);
  }

  public async inventoryUnreserve(params: InventoryUnreserveRequestParams) {
    return await this.axios.post<ReservedInventory>('/inventory/api/inventory/unreserve', params);
  }

  public async inventorySimUpload(file: File, params: InventorySimUploadRequestParams) {
    const formData = new FormData();
    formData.append('file', file);

    return await this.axios.post<FileUploadResponse>('/inventory/api/inventory/sim/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    });
  }

  public async inventoryQrCodeUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await this.axios.post<FileUploadResponse>('/inventory/api/inventory/sim/upload-qr-code', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async inventoryMsisdnUpload(file: File, params: InventoryQrCodeUploadRequestParams) {
    const formData = new FormData();
    formData.append('file', file);

    return await this.axios.post<FileUploadResponse>('/inventory/api/inventory/msisdn/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    });
  }

  public async inventoryDeliver(params: DeliverInventoryRequestParams) {
    return await this.axios.post<ReservedInventory>('/inventory/api/inventory/deliver', params);
  }

  public async inventoryReserveRandom(params: InventoryReserveRandomRequestParams) {
    return await this.axios.post<ReservedInventory>('/inventory/api/inventory/reserve-random', params);
  }

  public async inventory(id: string) {
    return await this.axios.get<Inventory>(`/inventory/api/inventory/${id}`);
  }

  public async inventoryTypeList() {
    return await this.axios.get<InventoryTypeDetails[]>(`/inventory/api/inventory-type`);
  }

  public async inventoryType(id: string) {
    return await this.axios.get<InventoryTypeDetails>(`/inventory/api/inventory-type/${id}`);
  }

  public async queryInventoryBySerialNumber(params: QueryInventoryBySerialRequestParams) {
    return await this.axios.get<Inventory[]>(`/inventory/api/inventory/query-multiple`, { params });
  }
}
