import { Controller, Path, PathValue, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { interpolatedTranslation } from '../../app/i18n';

interface Props<T> {
  id?: string;
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
  disabled?: boolean;
  isRequired?: boolean;
  label: string;
  minDate?: Date;
  maxDate?: Date;
}

export const DateField = <T extends Record<string, any>>({
  id,
  name,
  label,
  minDate,
  maxDate,
  disabled = false,
  isRequired = false,
}: Props<T>) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ref, onChange }, fieldState: { error } }) => (
        <DatePicker
          label={label}
          disabled={disabled}
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          value={value ? new Date(value) : null}
          onChange={value => {
            onChange(value as PathValue<T, Path<T>>);
          }}
          slotProps={{
            textField: {
              id,
              variant: 'standard',
              fullWidth: true,
              error: !!error?.message,
              required: isRequired,
              helperText: error?.message && t(...interpolatedTranslation(error.message)),
            },
          }}
          inputRef={ref}
        />
      )}
    />
  );
};
