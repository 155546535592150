import { ServiceCategory, StatusHistoryItem } from '../crm/types';

export interface AccountUpcomingInvoiceAmount {
  discount: number;
  amount: number;
  vat: number;
  currency: string;
}

export interface InvoiceDetails {
  id: string;
  tenantId: string;
  accountId: string;
  billDate: string;
  customerId: string;
  customerType: string;
  firstName: string;
  lastName: string;
  companyName: string;
  birthDate: string;
  customerCode: string;
  contactPhoneNr: string;
  contactEmail: string;
  language: string;
  invoiceDeliveryType: string;
  iban: string;
  address: Address;
  billCycleId: string;
  billCycleNumber: number;
  periodFrom: string;
  periodTo: string;
  createdAt: string;
  sum: Sum[];
  totalSum: number;
  billEntries: BillEntry[];
  cdrBillEntries: CdrBillEntry[];
}

export interface CdrBillEntry {
  id: string;
  tenantId: string;
  billDate: string;
  accountId: string;
  subscriptionId: string;
  serviceCategory?: ServiceCategory;
  measureUnit: string;
  usage: number;
  sum: Sum;
  periodFrom: string;
  periodTo: string;
}

export interface BillEntry {
  id: string;
  tenantId: string;
  accountId: string;
  subscriptionId: string;
  billDate: string;
  orderOfferId: string;
  productId: string;
  productName: string;
  productCommercialName: string;
  billEntryType: string;
  customerId: string;
  billCycleNumber: number;
  price: Sum;
  amount: number;
  sum: Sum;
  periodFrom: string;
  periodTo: string;
  children?: BillEntry[];
  subscriptionIdentifier?: string;
}

export interface Sum {
  discount: number;
  amount: number;
  amountNoVat: number;
  chargeCode?: string;
  vat: number;
  currency: string;
}

interface Address {
  region: string;
  zipCode: string;
  city: string;
  district: string;
  addressLine1: string;
  addressLine2: string;
}

export interface BillRun {
  id: string;
  identifier: string;
  status: BillRunStatus;
  usagePeriodFrom: Date;
  usagePeriodTo: Date;
  createdAt: Date;
  sum: number;
  customers: number;
  accounts: number;
  subscriptions: number;
}

export interface BillRunDetails extends BillRun {
  tenantId: string;
  billCycleNumberFrom: number;
  billCycleNumberTo: number;
  sumsByType: Record<BillEntryType, number | string>;
  cdrSum: number;
  cdrSumsByType: Record<string, number | string>;
}

export interface PaymentBatch {
  id: string;
  tenantId: string;
  billCycleId: string;
  billCycleType: BillCycleType;
  totalPayments: number;
  finishedPayments: number;
  failedPayments: number;
  totalSum: number;
  createdAt: string;
  version: number;
}

export interface PrepaidRenewalBatch {
  accountId?: string;
  amount?: number;
  billCycleId?: string;
  billCycleIdentifier?: string;
  billCycleNumber?: number;
  createdAt?: string;
  customerId?: string;
  id?: string;
  orderOfferId?: string;
  orderProductId?: string;
  periodFrom?: string;
  periodTo?: string;
  price?: Omit<Sum, 'discount'>;
  productCommercialName?: string;
  productId?: string;
  productName?: string;
  status?: string;
  statusHistory?: Array<Omit<StatusHistoryItem, 'eventName'>>;
  subscriptionId?: string;
  subscriptionIdentifier?: string;
  sum?: Omit<Sum, 'discount'>;
  tenantId?: string;
  version?: number;
}

export enum BillRunStatus {
  CONFIRMED = 'CONFIRMED',
  GENERATED = 'GENERATED',
  INVOICED = 'INVOICED',
  CANCELLED = 'CANCELLED',
  PAYMENTS_FIRST_PASSED = 'PAYMENTS_FIRST_PASSED',
  PAYMENTS_SECOND_PASSED = 'PAYMENTS_SECOND_PASSED',
  INVOICES_RELEASED = 'INVOICES_RELEASED',
  INVOICE_NOTIFIED = 'INVOICE_NOTIFIED',
}

export enum BillEntryType {
  RECURRENT_CHARGE = 'RECURRENT_CHARGE',
  SIMPLE_CHARGE = 'SIMPLE_CHARGE',
  PAYMENT = 'PAYMENT',
  OVERPAYMENT_REFUND = 'OVERPAYMENT_REFUND',
  MONEY_TRANSFER_SOURCE = 'MONEY_TRANSFER_SOURCE',
  MONEY_TRANSFER_TARGET = 'MONEY_TRANSFER_TARGET',
  CORRECTION = 'CORRECTION',
}

export enum BillCycleType {
  POSTPAID = 'POSTPAID',
  PREPAID = 'PREPAID',
}
