import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { CssBaseline, StyledEngineProvider, Theme } from '@mui/material';

import {
  ApplicationContextProvider,
  PermissionsContextProvider,
  LocalizationWithLocaleAdapterProvider,
  StripeProvider,
} from './app/contexts';
import { Layout, ProtectedRoute, ThemeProvider } from './ui';
import { ErrorFallback, NotFoundPage } from './ui/router';
import { appRoutes } from './app/router';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const routes = appRoutes();
export const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      ...routes.list().map(route => ({
        ...route,
        element: (
          <ErrorBoundary fallback={ErrorFallback}>
            <ProtectedRoute path={route.path}>{route.element}</ProtectedRoute>
          </ErrorBoundary>
        ),
      })),
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ErrorBoundary fallback={ErrorFallback}>
        <PermissionsContextProvider routes={routes}>
          <ApplicationContextProvider>
            <LocalizationWithLocaleAdapterProvider>
              <ThemeProvider>
                <StripeProvider>
                  <RouterProvider router={browserRouter} />
                </StripeProvider>
              </ThemeProvider>
            </LocalizationWithLocaleAdapterProvider>
          </ApplicationContextProvider>
        </PermissionsContextProvider>
      </ErrorBoundary>
    </StyledEngineProvider>
  );
};

export default App;
