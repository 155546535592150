import * as yup from 'yup';

import { createClient } from '../api-hooks';
import { SearchApi } from '../../api/search/api';
import { CustomerCodePresentParams, LegalRepresentativeCodePresentParams, ValidateEmailUniqueParams } from './types';

const customerCodePresent = (params: CustomerCodePresentParams) =>
  params?.customer?.customerCode === params?.customerCode;

const legalRepresentativeCodePresent = (params: LegalRepresentativeCodePresentParams) =>
  params?.customer?.legalRepresentative?.customerCode === params?.customerCode;

const validateEmailUnique = async ({
  email = '',
  customerCodeCallback,
  getAccessToken,
  testContext,
}: ValidateEmailUniqueParams) => {
  if (email?.length) {
    const token = await getAccessToken();
    const axiosClient = createClient(token);
    const api = new SearchApi(axiosClient);
    const customerCode = testContext?.parent?.customerCode;

    const { data: customers } = await api.searchByCustomerEmail(email);

    const isMyEmail = customerCode
      ? customers?.some(customer =>
          customerCodeCallback({
            customer,
            customerCode,
          }),
        )
      : false;

    return isMyEmail || customers?.length === 0;
  }

  return true;
};

export const validateCustomerEmailUnique = async (params: Omit<ValidateEmailUniqueParams, 'customerCodeCallback'>) => {
  const existingContactEmail = params?.testContext?.options?.context?.existingContactEmail;
  const emailNotChanged = existingContactEmail === params?.email;
  const existingEmailValid = yup.string().email('common:errors.invalid-email').isValidSync(existingContactEmail);

  if (emailNotChanged && existingEmailValid) return true;

  return await validateEmailUnique({
    ...params,
    customerCodeCallback: customerCodePresent,
  });
};

export const validateLegalRepresentativeEmailUnique = async (
  params: Omit<ValidateEmailUniqueParams, 'customerCodeCallback'>,
) => {
  const existingLegalRepresentativeContactEmail =
    params?.testContext?.options?.context?.legalRepresentative?.existingContactEmail;
  const emailNotChanged = existingLegalRepresentativeContactEmail === params?.email;
  const existingEmailValid = yup
    .string()
    .email('common:errors.invalid-email')
    .isValidSync(existingLegalRepresentativeContactEmail);

  if (emailNotChanged && existingEmailValid) return true;

  return await validateEmailUnique({
    ...params,
    customerCodeCallback: legalRepresentativeCodePresent,
  });
};
