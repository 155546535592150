import { AxiosInstance } from 'axios';

import { ListRequestParams, ScrollableEntry, SuccessResponse } from '../types';
import {
  AccountFullView,
  AccountsListItem,
  ConsentsApiForm,
  ContactLog,
  ContactLogForm,
  ContactLogType,
  Customer,
  ICustomerDocuments,
  Invoice,
  LogsRequestParams,
  Payment,
  Subscription,
  SubscriptionsListItem,
  Topup,
} from './types';
import { PrivateCustomer } from '../../business';

export class CrmApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async customer(customerId: string) {
    return await this.axios.get<Customer>(`/crm/api/customer/${customerId}`);
  }

  public async updateCustomer(customerId: string, form: PrivateCustomer) {
    return await this.axios.put<SuccessResponse>(`/crm/api/customer/${customerId}`, form);
  }

  public async updateCustomerConsents(customerId: string, form: ConsentsApiForm) {
    return await this.axios.put<SuccessResponse>(`/crm/api/customer/${customerId}/consents`, form);
  }

  public async account(accountId: string) {
    return await this.axios.get<AccountFullView>(`/crm/api/account/${accountId}`);
  }

  public async accounts(customerId: string) {
    return await this.axios.get<AccountsListItem[]>(`/crm/api/customer/${customerId}/accounts`);
  }

  public async subscriptions(accountId: string, params?: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<SubscriptionsListItem>>(`/crm/api/account/${accountId}/subscriptions`, {
      params,
    });
  }

  public async subscription(subscriptionId: string) {
    return await this.axios.get<Subscription>(`/crm/api/subscription/${subscriptionId}`);
  }

  public async subscriptionByInventory(inventory: string) {
    return await this.axios.get<Subscription[]>(`/crm/api/subscription/find?inventory=${inventory}`);
  }

  public async topup(subscriptionId: string) {
    return await this.axios.get<Topup[]>(`/crm/api/subscription/${subscriptionId}/topup`);
  }

  public async contactLog(contactLogId: string) {
    return await this.axios.get<ContactLog>(`/crm/api/contact-log/${contactLogId}`);
  }

  public async updateContactLog(contactLogId: string, params: ContactLogForm) {
    return await this.axios.put<SuccessResponse>(`/crm/api/contact-log/${contactLogId}`, params);
  }

  public async updateContactLogType(contactLogTypeId: string, name: string) {
    return await this.axios.put<SuccessResponse>(`/crm/api/contact-log/type/${contactLogTypeId}`, { name });
  }

  public async createContactLog(customerId: string, params: ContactLogForm) {
    return await this.axios.post<SuccessResponse>(`/crm/api/contact-log/${customerId}`, params);
  }

  public async contactLogTypes() {
    return await this.axios.get<ContactLogType[]>('/crm/api/contact-log/type');
  }

  public async createContactLogType(name: string) {
    return await this.axios.post<SuccessResponse>('/crm/api/contact-log/type', { name });
  }

  public async contactLogs(customerId: string, params?: LogsRequestParams) {
    return await this.axios.get<ScrollableEntry<ContactLog>>(`/crm/api/contact-log/list/${customerId}`, { params });
  }

  public async getCustomerDocuments(customerId: string) {
    return await this.axios.get<ICustomerDocuments>(`/crm/api/customer/${customerId}/documents`);
  }

  public async updateCustomerDocuments(customerId: string, documents: ICustomerDocuments) {
    return await this.axios.put<SuccessResponse>(`/crm/api/customer/${customerId}/documents`, documents);
  }

  public async getInvoices(accountId: string) {
    return await this.axios.get<Invoice[]>(`/crm/api/account/${accountId}/invoice`);
  }

  public async payments(accountId: string, params?: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<Payment>>(`/crm/api/payment/${accountId}`, { params });
  }
}
