import { init, reactRouterV6BrowserTracingIntegration, replayIntegration, setTag, setUser } from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { CurrentUser } from './api/sso/types';
import { Tenant } from './api/types';

export const SENTRY_TENANT_ID_TAG = 'tenant';
export const SENTRY_TENANT_LOCALE_TAG = 'locale';

const API_URL_REGEXP = /^https:\/\/.*opencloudbss\.com\/.*\/api/;
const SENTRY_DSN = process.env.REACT_APP_sentry_dsn;
const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;

if (SENTRY_DSN) {
  init({
    dsn: SENTRY_DSN,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ],
    initialScope: {
      tags: {
        environment: ENVIRONMENT_NAME,
        release: getReleaseName(),
      },
    },
    environment: ENVIRONMENT_NAME,
    release: getReleaseName(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [API_URL_REGEXP],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function getReleaseName() {
  return `${ENVIRONMENT_NAME || ''}-${process.env.REACT_APP_RELEASE_NAME || ''}`;
}

export const setSentryUser = (user: CurrentUser | undefined) => {
  if (!SENTRY_DSN || !user?.id) return;

  setUser({ id: user.id, tenantId: user?.tenantId });
};

export const setSentryTenantId = (tenantId: string | undefined) => {
  if (!SENTRY_DSN || !tenantId) return;

  setTag(SENTRY_TENANT_ID_TAG, tenantId);
};

export const setSentryTenantLocale = (locale: string | undefined) => {
  if (!SENTRY_DSN || !locale) return;

  setTag(SENTRY_TENANT_LOCALE_TAG, locale);
};

export const setSentryTags = (tenant: Tenant | undefined) => {
  if (!SENTRY_DSN || !tenant?.id) return;

  setSentryTenantId(tenant.id);
  setSentryTenantLocale(tenant?.locale);
};
