import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { Alert, Stack } from '@mui/material';

import { LoadingButton } from '../../LoadingButton';

interface Props {
  callbackUrl: string;
}

export const PaymentButton: FC<Props> = ({ callbackUrl }) => {
  const { t } = useTranslation('common');
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Stack gap={1}>
      {!!message && <Alert severity="error">{message}</Alert>}
      <LoadingButton loading={isLoading} variant="contained" onClick={handlePayClick}>
        {t('payments.stripe.checkout-modal.button.pay')}
      </LoadingButton>
    </Stack>
  );

  async function handlePayClick() {
    if (!stripe || !elements) return;

    try {
      setIsLoading(true);
      setMessage(undefined);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: callbackUrl,
        },
      });
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage(t('error-warning-retry'));
      }
    } catch (error) {
      setMessage(t('error-warning-retry'));
    } finally {
      setIsLoading(false);
    }
  }
};
