import { FallbackRender } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle, Box, Button, Paper, Stack, Typography } from '@mui/material';

import { routePaths, useRouter } from '../../app/router';

export const ErrorFallback: FallbackRender = ({ error, resetError }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
      <Paper variant="outlined" sx={{ p: 3 }}>
        <Stack gap={1}>
          <Alert severity="error">
            <AlertTitle>{t('common:error-warning')}</AlertTitle>
            <Stack gap={1}>
              <Typography variant="subtitle1">{error?.toString()}</Typography>
            </Stack>
          </Alert>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button variant="outlined" color="primary" onClick={resetError}>
              {t('common:error-action')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleHomeClick}>
              {t('common:go-home')}
            </Button>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );

  function handleHomeClick() {
    router.push(routePaths.pos.order);
    resetError();
  }
};
