import { useApplicationContext } from '../contexts';
import { CustomerFeatures, isFeatureEnabled } from '../../business';

export const useFeature = () => {
  const { tenant } = useApplicationContext();

  return {
    addressSectionEnabled: isFeatureEnabled(
      tenant?.modules?.CRM?.features?.CUSTOMER?.features,
      CustomerFeatures.AddressAvailability,
    ),
    identitySectionEnabled: isFeatureEnabled(
      tenant?.modules?.CRM?.features?.CUSTOMER?.features,
      CustomerFeatures.IdConfirmationAvailability,
    ),
    contactEmailRequired: isFeatureEnabled(
      tenant?.modules?.CRM?.features?.CUSTOMER?.features,
      CustomerFeatures.RequireEmailField,
    ),
  };
};
