import React from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { Stack } from '@mui/material';

import { useApplicationContext, NotificationContextProvider, ModalProvider } from '../../app/contexts';
import { GlobalContextsLoader, OverlayedLoader } from '..';
import { ContentWrapper } from './ContentWrapper';
import { NavigationMenu } from './navigation-menu/NavigationMenu';
import { ErrorFallback } from '../router';

export const Layout: React.FC = React.memo(() => {
  const { loading } = useApplicationContext();

  if (loading) {
    return <OverlayedLoader open={loading} />;
  }

  return (
    <Stack
      direction="row"
      height="100vh"
      sx={{
        background: theme => theme.palette.background.default,
      }}
    >
      <NavigationMenu />
      <ContentWrapper>
        <NotificationContextProvider>
          <ErrorBoundary fallback={ErrorFallback}>
            <GlobalContextsLoader>
              <ModalProvider>
                <React.Suspense fallback={<OverlayedLoader />}>
                  <Outlet />
                </React.Suspense>
              </ModalProvider>
            </GlobalContextsLoader>
          </ErrorBoundary>
        </NotificationContextProvider>
      </ContentWrapper>
    </Stack>
  );
});
