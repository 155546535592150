import luhn from 'luhn';
import { format } from 'date-fns';

import { Customer } from '../../api/crm/types';
import { Consents, ConsentType } from '../schemas';
import { Address } from '../../api/order/types';

export function isSouthAfricanCustomerCodeValid(value: string | undefined, birthDate: Date) {
  if (!value) return true;

  const birthDateNumberPrefix = format(birthDate, 'yyMMdd');
  const pattern = `${birthDateNumberPrefix}\\d{4}[01]\\d{2}`;

  return !!value.match(pattern) && value.length === 13 && luhn.validate(value);
}

export const consentsToForm = (customer: Customer | undefined) => {
  if (!customer?.consents?.length) return {};

  return {
    marketingConsent: customer.consents.find(c => c.consentType === 'MARKETING')?.enabled,
    segmentationConsent: customer.consents.find(c => c.consentType === 'SEGMENTATION')?.enabled,
  };
};

export const consentsFromForm = ({ marketingConsent, segmentationConsent }: Consents) => [
  { consentType: ConsentType.Marketing, enabled: marketingConsent },
  { consentType: ConsentType.Segmentation, enabled: segmentationConsent },
];

export const getCustomerName = (customer: Pick<Customer, 'customerType' | 'companyName' | 'firstName' | 'lastName'>) =>
  customer?.customerType === 'BUSINESS' ? getBusinessCustomerName(customer) : getPrivateCustomerName(customer);

export const getPrivateCustomerName = (customer: Pick<Customer, 'firstName' | 'lastName'>) => {
  const personalNamePresent = !!customer?.firstName || !!customer?.lastName;
  return personalNamePresent ? `${customer?.firstName || ''} ${customer?.lastName || ''}` : '';
};

export const getBusinessCustomerName = (customer: Pick<Customer, 'companyName'>) => customer?.companyName || '';

export const getCustomerAddress = (address: Pick<Address, 'addressLine1' | 'addressLine2'> | undefined) => {
  if (!address) return '';

  return [address?.addressLine1, address?.addressLine2].filter(Boolean).join(' ');
};
