import React, { useContext, useMemo } from 'react';

import { BillingClassifierEntry } from '../../api/billing-classifier/types';
import { CrmClassifierEntry } from '../../api/crm-classifier/types';
import { OrderManagementClassifierEntry } from '../../api/order-management-classifier/types';
import { PosClassifierEntry } from '../../api/pos-classifier/types';
import { ProductClassifierEntry } from '../../api/product-classifier/types';
import { ProvisioningClassifier } from '../../api/provisioning-classifier/types';
import { TaskClassifierEntry } from '../../api/task-classifier/types';
import { TicketClassifierEntry } from '../../api/ticket-classifier/types';
import { Tenant } from '../../api/types';
import { useApi } from '../api-hooks';
import { usePermissionsContext } from './PermissionsContext';
import { JAVA_21_LOCALE, setDateFnsLocale } from '../../business';
import { setSentryTags } from '../../sentry';

interface ApplicationContextValue {
  loading: boolean;
  errors: string[];
  tenant?: Tenant;
  posClassifiers: PosClassifierEntry[];
  crmClassifiers: CrmClassifierEntry[];
  taskClassifiers: TaskClassifierEntry[];
  ticketClassifiers: TicketClassifierEntry[];
  productClassifiers: ProductClassifierEntry[];
  provisioningClassifiers: ProvisioningClassifier[];
  orderManagementClassifiers: OrderManagementClassifierEntry[];
  billingClassifiers: BillingClassifierEntry[];
}

const ApplicationContext = React.createContext<ApplicationContextValue>({
  loading: true,
  errors: [],
  posClassifiers: [],
  crmClassifiers: [],
  taskClassifiers: [],
  ticketClassifiers: [],
  productClassifiers: [],
  orderManagementClassifiers: [],
  provisioningClassifiers: [],
  billingClassifiers: [],
});

export const useApplicationContext = () => useContext(ApplicationContext);

export const ApplicationContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { tenantId } = usePermissionsContext();
  const tenant = useApi(async api => await api.tenant.tenant(tenantId), [tenantId], {
    onSuccess: async ({ data: tenant }) => {
      setSentryTags(tenant);
      await setDateFnsLocale(tenant?.locale as JAVA_21_LOCALE);
    },
  });
  const posClassifiers = useApi(api => api.posClassifier.list());
  const productClassifiers = useApi(api => api.productClassifier.list());
  const ticketClassifiers = useApi(api => api.ticketClassifier.list());
  const taskClassifiers = useApi(api => api.taskClassifier.list());
  const crmClassifiers = useApi(api => api.crmClassifier.list());
  const orderManagementClassifiers = useApi(api => api.orderManagementClassifier.list());
  const provisioningClassifiers = useApi(api => api.provisioningClassifier.list());
  const billingClassifiers = useApi(api => api.billingClassifier.list());

  const errors = useMemo(
    () =>
      [
        tenant.error?.message,
        productClassifiers.error?.message,
        ticketClassifiers.error?.message,
        crmClassifiers.error?.message,
        orderManagementClassifiers.error?.message,
        provisioningClassifiers.error?.message,
        billingClassifiers.error?.message,
      ].filter(Boolean) as string[],
    [
      tenant.error?.message,
      productClassifiers.error?.message,
      ticketClassifiers.error?.message,
      crmClassifiers.error?.message,
      orderManagementClassifiers.error?.message,
      provisioningClassifiers.error?.message,
      billingClassifiers.error?.message,
    ],
  );

  return (
    <ApplicationContext.Provider
      value={{
        tenant: tenant.result?.data,
        posClassifiers: posClassifiers.result?.data ?? [],
        productClassifiers: productClassifiers.result?.data ?? [],
        ticketClassifiers: ticketClassifiers.result?.data ?? [],
        taskClassifiers: taskClassifiers.result?.data ?? [],
        crmClassifiers: crmClassifiers.result?.data ?? [],
        orderManagementClassifiers: orderManagementClassifiers.result?.data ?? [],
        provisioningClassifiers: provisioningClassifiers.result?.data ?? [],
        billingClassifiers: billingClassifiers.result?.data ?? [],
        errors,
        loading:
          provisioningClassifiers.loading ||
          posClassifiers.loading ||
          productClassifiers.loading ||
          ticketClassifiers.loading ||
          crmClassifiers.loading ||
          taskClassifiers.loading ||
          orderManagementClassifiers.loading ||
          billingClassifiers.loading ||
          tenant.loading,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
