export const JAVA_LOCALE_TO_DATE_FNS_MAP = {
  'sq-AL': 'sq',
  'ar-DZ': 'arDZ',
  'ar-BH': 'ar',
  'ar-EG': 'arEG',
  'ar-IQ': 'ar',
  'ar-JO': 'ar',
  'ar-KW': 'ar',
  'ar-LB': 'ar',
  'ar-LY': 'ar',
  'ar-MA': 'arMA',
  'ar-OM': 'ar',
  'ar-QA': 'ar',
  'ar-SA': 'arSA',
  'ar-SD': 'ar',
  'ar-SY': 'ar',
  'ar-TN': 'arTN',
  'ar-AE': 'ar',
  'ar-YE': 'ar',
  'be-BY': 'be',
  'bg-BG': 'bg',
  'ca-ES': 'ca',
  'zh-CN': 'zhCN',
  'zh-SG': 'zhCN',
  'zh-HK': 'zhHK',
  'zh-TW': 'zhTW',
  'hr-HR': 'hr',
  'cs-CZ': 'cs',
  'da-DK': 'da',
  'nl-BE': 'nlBE',
  'nl-NL': 'nl',
  'en-AU': 'enAU',
  'en-CA': 'enCA',
  'en-IN': 'enIN',
  'en-IE': 'enIE',
  'en-MT': 'en',
  'en-NZ': 'enNZ',
  'en-PH': 'en',
  'en-SG': 'en',
  'en-ZA': 'enZA',
  'en-GB': 'enGB',
  'en-US': 'enUS',
  'et-EE': 'et',
  'fi-FI': 'fi',
  'fr-BE': 'fr',
  'fr-CA': 'frCA',
  'fr-FR': 'fr',
  'fr-LU': 'fr',
  'fr-CH': 'frCH',
  'de-AT': 'deAT',
  'de-DE': 'de',
  'de-LU': 'de',
  'de-CH': 'de',
  'el-CY': 'el',
  'el-GR': 'el',
  'he-IL': 'he',
  'hi-IN': 'hi',
  'hu-HU': 'hu',
  'is-IS': 'is',
  'id-ID': 'id',
  'ga-IE': 'ga',
  'it-IT': 'it',
  'it-CH': 'itCH',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'lv-LV': 'lv',
  'lt-LT': 'lt',
  'mk-MK': 'mk',
  'ms-MY': 'ms',
  'mt-MT': 'mt',
  'no-NO': 'nb', // default to Norwegian Bokmål
  'nb-NO': 'nb',
  'nn-NO': 'nn',
  'no-NO-x-lvariant-NY': 'nn', // Norwegian Nynorsk
  'pl-PL': 'pl',
  'pt-BR': 'ptBR',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'ru-RU': 'ru',
  'sr-BA': 'sr',
  'sr-ME': 'sr',
  'sr-RS': 'sr',
  'sr-Latn-BA': 'srLatn',
  'sr-Latn-ME': 'srLatn',
  'sr-Latn-RS': 'srLatn',
  'sk-SK': 'sk',
  'sl-SI': 'sl',
  'es-AR': 'es',
  'es-BO': 'es',
  'es-CL': 'es',
  'es-CO': 'es',
  'es-CR': 'es',
  'es-DO': 'es',
  'es-EC': 'es',
  'es-SV': 'es',
  'es-GT': 'es',
  'es-HN': 'es',
  'es-MX': 'es',
  'es-NI': 'es',
  'es-PA': 'es',
  'es-PY': 'es',
  'es-PE': 'es',
  'es-PR': 'es',
  'es-ES': 'es',
  'es-US': 'es',
  'es-UY': 'es',
  'es-VE': 'es',
  'sv-SE': 'sv',
  'th-TH': 'th',
  'tr-TR': 'tr',
  'uk-UA': 'uk',
  'vi-VN': 'vi',
};

export type JAVA_21_LOCALE = keyof typeof JAVA_LOCALE_TO_DATE_FNS_MAP;
