import './sentry';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import '@aws-amplify/ui-react/styles.css';
import './styles/amplify-authenticator.css';
import { Amplify, Auth } from 'aws-amplify';
import i18next from 'i18next';

import { Authenticator } from '@aws-amplify/ui-react';

import { AuthProvider } from './app/contexts';
import { configureI18n } from './app/i18n';
import { awsConfig } from './aws-exports-env-overrides';
import awsAuth from './awsauth';
import App from './App';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

Amplify.configure(awsConfig);
Auth.configure({
  oauth: awsAuth,
});

const e2e = process.env.REACT_APP_ENVIRONMENT === 'e2e';

const container = document.getElementById('root');
const root = createRoot(container!);

(async () => {
  await configureI18n(i18next, initReactI18next, {
    react: { useSuspense: true },
    returnNull: false,
    debug: process.env.NODE_ENV !== 'production',
  });

  root.render(
    <React.StrictMode>
      {e2e ? (
        <App />
      ) : (
        <Authenticator hideSignUp variation="modal">
          {({ signOut, user }) => (
            <AuthProvider cognitoUser={user} logout={signOut}>
              <App />
            </AuthProvider>
          )}
        </Authenticator>
      )}
    </React.StrictMode>,
  );
})();
