import { FC, createContext, PropsWithChildren, useContext } from 'react';

import { ORDER_TYPE } from '../../api/order/types';
import { SubscriptionProduct } from '../../api/crm/types';

interface Props {
  orderType: ORDER_TYPE;
  isPaymentFailed: boolean;
  existingProducts?: SubscriptionProduct[];
}

export type SelectOfferContextValue = Props;

const SelectOfferContext = createContext<SelectOfferContextValue>({
  orderType: ORDER_TYPE.NEW_SUBSCRIPTION,
  isPaymentFailed: false,
  existingProducts: undefined,
});

export const useSelectOfferContext = () => useContext(SelectOfferContext);

export const SelectOfferProvider: FC<PropsWithChildren<Props>> = ({
  children,
  orderType,
  isPaymentFailed,
  existingProducts,
}) => {
  return (
    <SelectOfferContext.Provider value={{ orderType, isPaymentFailed, existingProducts }}>
      {children}
    </SelectOfferContext.Provider>
  );
};
