import { FC } from 'react';

import { Step, StepButton, Stepper, Theme, useMediaQuery } from '@mui/material';

import { useWizardStepperParams } from './hooks';

interface Props {
  steps: string[];
}

export const WizardStepper: FC<Props> = ({ steps }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { activeStep, navigateToStep } = useWizardStepperParams();

  return (
    <Stepper orientation="horizontal" activeStep={activeStep} sx={{ height: '100%', width: '100%' }}>
      {steps.map((step, idx) => (
        <Step key={idx}>
          <StepButton
            onClick={() => navigateToStep(idx)}
            disableRipple
            disableTouchRipple
            sx={{ padding: 0, margin: 0 }}
          >
            {isMobile ? null : step}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};
