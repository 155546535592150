import { FC } from 'react';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';

import { Stack } from '@mui/material';

import { PaymentButton } from './PaymentButton';
import { useStripeContext } from '../../../app/contexts';

interface Props {
  publicKey: string;
  clientSecret: string;
  callbackUrl: string;
}

export const PaymentForm: FC<Props> = ({ clientSecret, publicKey, callbackUrl }) => {
  const stripePromise = useStripeContext();
  const options = { clientSecret };

  if (!clientSecret || !publicKey) return null;

  return (
    <Elements stripe={stripePromise} options={options}>
      <Stack gap={1} mb={1}>
        <PaymentElement />
        <PaymentButton callbackUrl={callbackUrl} />
      </Stack>
    </Elements>
  );
};
