import { ClassifierEntry } from '../types';

export enum CRM_CLASSIFIER {
  OFFER_STATUS = 'offerStatus',
  CONSENT_TYPE = 'consentType',
  IDENTITY_TYPE = 'identityType',
  COUNTRIES = 'countries',
  CONTACT_PHONE_TYPE = 'contactPhoneType',
  PRODUCT_STATUS = 'productStatus',
  SUSPEND_REASON = 'suspendReason',
}

export type CrmClassifierEntry = ClassifierEntry<CRM_CLASSIFIER>;
