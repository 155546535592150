import { FC, PropsWithChildren } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { OverlayedLoader } from '../../ui';
import { getDateFnsLocale, JAVA_21_LOCALE } from '../../business';
import { useApplicationContext } from './ApplicationContext';

export const LocalizationWithLocaleAdapterProvider: FC<PropsWithChildren> = ({ children }) => {
  const { tenant, loading } = useApplicationContext();

  if (loading) return <OverlayedLoader />;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getDateFnsLocale(tenant?.locale as JAVA_21_LOCALE)}
    >
      {children}
    </LocalizationProvider>
  );
};
