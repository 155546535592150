import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { routePaths, useRouter } from '../../app/router';

export const useWizardStepperParams = () => {
  const router = useRouter<{}, { step?: string }>();
  const activeStep = Number(router.query.step ?? 0);
  const navigate = useNavigate();
  const customerType = router.query.customerType;

  const navigateToStep = useCallback(
    (step: number) => {
      const params = new URLSearchParams({});
      if (step > 0) {
        params.set('step', step.toString());
      }

      if (customerType) {
        params.set('customerType', customerType);
      }

      navigate({ search: params.toString() }, { replace: false });
    },
    [navigate, customerType],
  );

  const navigateToPrevStep = useCallback(() => {
    const prevStep = activeStep > 0 ? activeStep - 1 : 0;
    navigateToStep(prevStep);
  }, [activeStep, navigateToStep]);

  const navigateToNextStep = useCallback(() => {
    const nextStep = activeStep + 1;
    navigateToStep(nextStep);
  }, [activeStep, navigateToStep]);

  const onOrderIdUpdate = useCallback(
    (orderId: string) => navigate(routePaths.pos.orderWithId, { state: { orderId }, replace: true }),
    [navigate],
  );

  return { activeStep, navigateToStep, navigateToPrevStep, navigateToNextStep, onOrderIdUpdate };
};
