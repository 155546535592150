import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Alert, Stack } from '@mui/material';

import { ContentModal } from '../../modal';
import { useApi } from '../../../app/api-hooks';
import { useTenantSecret } from '../../../app/hooks';
import { PaymentForm } from './PaymentForm';
import { routePaths } from '../../../app/router';
import { Loader } from '../../Loader';

interface Props {
  open: boolean;
  omOrderId: string | undefined;
  onClose(): void;
}

export const StripeModal: FC<Props> = ({ open, omOrderId, onClose }) => {
  const { t } = useTranslation('common');
  const stripePublicKey: string | undefined = useTenantSecret('REACT_APP_STRIPE_PUBLIC_KEY');
  const omOrder = useApi(
    api => {
      if (!omOrderId) return Promise.reject();
      return api.orderManagement.order(omOrderId);
    },
    [open],
  );
  const clientSecret = omOrder.result?.data?.orderPayment?.clientSessionId;
  const warningMessage = getWarningMessage();

  if (!open || !omOrderId) return null;

  return (
    <ContentModal
      titleLabel={t('payments.stripe.checkout-modal.title')}
      cancelLabel={t('payments.stripe.checkout-modal.button.return')}
      isOpen={open}
      onCancel={onClose}
      keepMounted={false}
    >
      <Stack gap={1} mb={1}>
        {omOrder.loading && <Loader />}
        {!!warningMessage && !omOrder.loading && <Alert severity="warning">{warningMessage}</Alert>}
        {!warningMessage && !omOrder.loading && (
          <PaymentForm publicKey={stripePublicKey!} clientSecret={clientSecret!} callbackUrl={getCallbackUrl()} />
        )}
      </Stack>
    </ContentModal>
  );

  function getWarningMessage() {
    if (typeof stripePublicKey !== 'string') return t('payments.stripe.error.missing-configuration');
    if (typeof clientSecret !== 'string') return t('payments.stripe.error.missing-session-id');

    return undefined;
  }

  function getCallbackUrl() {
    return (
      window.location.origin +
      generatePath(routePaths.orderManagement.order, {
        customerId: omOrder.result?.data?.customer?.id ?? '',
        orderId: omOrderId,
      })
    );
  }
};
